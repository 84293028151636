import type { Language } from '@magicschool/supabase/types';

export const languageLabels: Record<Language, string> = {
  'en-us': 'English (US)',
  'en-gb': 'English (UK)',
  ar: 'عربي (Arabic)',
  es: 'Español (Spanish)',
  de: 'Deutsch (German)',
  fr: 'Français (French)',
  'fr-ca': 'Français (Canadian French)',
  'zh-cn': '普通话 (Mandarin)',
  'zh-hk': '廣東話 (Cantonese)',
  hi: 'हिंदी (Hindi)',
  he: 'עִברִית (Hebrew)',
  ja: '日本語 (Japanese)',
  it: 'Italiano (Italian)',
  id: 'Bahasa Indonesia (Indonesian)',
};
