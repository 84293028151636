'use client';

import { useStore } from '@/features/store';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
export const LocalizationTag = () => {
  const roleSelected = useStore(({
    AuthFormStoreData: af
  }) => af.roleSelected, []);
  if (roleSelected) return null;
  return <Typography data-sentry-element="Typography" data-sentry-component="LocalizationTag" data-sentry-source-file="LocalizationTagClient.tsx">
      <FormattedMessage id="signup-role.localization-tag" data-sentry-element="FormattedMessage" data-sentry-source-file="LocalizationTagClient.tsx" />
    </Typography>;
};