'use client';

import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { type LocaleData, getLocaleData } from '@/features/locales/getLocaleData';
import type { Language } from '@magicschool/supabase/types';
import { IntlProvider } from 'react-intl';
type LocaleContextType = {
  locale: string;
  onLocaleChange: (locale: Language) => void;
};
const initialState: LocaleContextType = {
  locale: 'en-us',
  // biome-ignore lint/suspicious/noEmptyBlockStatements: This is a placeholder function
  onLocaleChange: () => {}
};
export const LocaleContext = React.createContext(initialState);

// ==============================|| LOCALIZATION ||============================== //
interface LocalsProps {
  locale?: Language;
  children: React.ReactNode;
}
export const LocalesProvider = ({
  children,
  locale = 'en-us'
}: LocalsProps) => {
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [messages, setMessages] = useState<LocaleData | undefined>();
  const setLocaleCookie = useCallback((locale: Language) => {
    document.cookie = `locale=${locale};path=/;max-age=31536000`;
  }, []);
  useEffect(() => {
    if (currentLocale) {
      setLocaleCookie(currentLocale);
    }
    const doIt = async () => {
      const data = await getLocaleData(currentLocale);
      setMessages(data);
    };
    doIt();
  }, [currentLocale, setLocaleCookie]);
  const onChangeLocale = useCallback((locale: Language) => {
    setCurrentLocale(locale);
    setLocaleCookie(locale);
  }, [setLocaleCookie]);
  return <>
      {messages && <IntlProvider locale={currentLocale} defaultLocale="en-us" messages={messages}>
          <LocaleContext.Provider value={{
        locale: currentLocale,
        onLocaleChange: onChangeLocale
      }}>{children}</LocaleContext.Provider>
        </IntlProvider>}
    </>;
};