import MUIAvatar, { type AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { forwardRef } from 'react';
export interface AvatarProps extends MuiAvatarProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  color?: 'primary' | 'secondary' | 'ternary';
  clickable?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}
const avatarSizeMap = {
  small: {
    width: '22px',
    height: '22px',
    fontSize: '1rem'
  },
  medium: {
    width: '34px',
    height: '34px',
    fontSize: '1.2rem'
  },
  large: {
    width: '44px',
    height: '44px',
    fontSize: '1.5rem'
  },
  extraLarge: {
    width: '100px',
    height: '100px',
    fontSize: '2rem'
  }
};
const getAvatarColorSx = (clickable: boolean, color: AvatarProps['color']) => {
  if (!color) return {};
  const colorMap = {
    primary: {
      color: 'primary.main',
      bgcolor: 'background.paper',
      borderColor: 'primary.main',
      '&:hover': clickable ? {
        borderColor: 'primary.dark',
        bgcolor: 'primary.dark',
        color: 'primary.light'
      } : undefined
    },
    secondary: {
      borderColor: 'secondary.light',
      bgcolor: 'secondary.light',
      color: 'secondary.dark',
      '&:hover': clickable ? {
        borderColor: 'secondary.main',
        bgcolor: 'secondary.main',
        color: 'secondary.light'
      } : undefined
    },
    ternary: {
      borderColor: 'secondary.light',
      bgcolor: 'secondary.200',
      '&:hover': clickable ? {
        borderColor: 'secondary.main',
        bgcolor: 'secondary.main',
        color: 'secondary.light'
      } : undefined
    }
  };
  return colorMap[color] || {};
};
export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({
  children,
  size,
  sx,
  color,
  clickable,
  ...rest
}, ref) => {
  const sizeSX = size ? avatarSizeMap[size] : {};
  const colorSX = getAvatarColorSx(!!clickable, color);
  const clickableSX = clickable ? {
    cursor: 'pointer',
    transition: 'all .2s ease-in-out'
  } : {};
  return <MUIAvatar ref={ref} sx={{
    ...sizeSX,
    ...colorSX,
    ...clickableSX,
    ...sx
  }} {...rest}>
      {children}
    </MUIAvatar>;
});