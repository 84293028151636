export const zIndices = {
  normal: 0,
  raised: 1,
  modal: 2,
  dropdown: 4,
  overlay: 100,
  overlayContent: 101,
  popover: 1_500,
  aboveAll: 100_000,
  adobe: 1_000_000,
} as const;

export type ZIndex = keyof typeof zIndices;
