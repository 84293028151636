import type { Language } from '@magicschool/supabase/types';
import type { MessageFormatElement } from 'react-intl';

const loadLocaleData = async (locale: Language) => {
  switch (locale) {
    case 'en-us':
      // @ts-ignore
      return await import('@/features/locales/data/en-us');
    case 'en-gb':
      return await import('@/features/locales/data/en-gb');
    case 'es':
      return await import('@/features/locales/data/es');
    case 'fr':
      return await import('@/features/locales/data/fr');
    case 'fr-ca':
      return await import('@/features/locales/data/fr');
    case 'he':
      return await import('@/features/locales/data/he');
    case 'hi':
      return await import('@/features/locales/data/hi');
    case 'zh-cn':
      return await import('@/features/locales/data/zh-cn');
    case 'zh-hk':
      return await import('@/features/locales/data/zh-hk');
    case 'ja':
      return await import('@/features/locales/data/ja');
    case 'de':
      return await import('@/features/locales/data/de');
    case 'ar':
      return await import('@/features/locales/data/ar');
    case 'it':
      return await import('@/features/locales/data/it');
    case 'id':
      return await import('@/features/locales/data/id');
    default:
      // @ts-ignore
      return await import('@/features/locales/data/en-us');
  }
};

export type LocaleData = Record<string, string> | Record<string, MessageFormatElement[]>;

export const getLocaleData = (locale: Language) => loadLocaleData(locale).then((data) => data.default as LocaleData);
