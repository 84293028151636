import { LocaleContext } from '@/components/ui-component/LocalesProviderClient';
import { useContext } from 'react';

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocalesProvider');
  }

  const { locale, onLocaleChange } = context;

  return { locale, onLocaleChange };
};
