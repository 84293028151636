'use client';

import { storage } from '@/features/storage';
import { useSiteConfig } from '@/hooks/useSiteConfig';
import Button from '@mui/material/Button';
import { StyledStickyBanner } from 'components/styled/Banner';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MILLISECONDS_IN_HOUR } from 'util/date';
const localStorageKey = (labelId: string | null | undefined, borkedFunction: string | null | undefined) => `${labelId || 'site'}-${borkedFunction || 'generic'}-maintenance-closed`;
const getHoursMessage = (showWarningBannerDateTime: Date | null | undefined, startDateTime: Date | null | undefined) => {
  if (!startDateTime || !showWarningBannerDateTime) return null;
  const now = new Date();
  const hoursUntilMaintenance = Math.floor((startDateTime.getTime() - now.getTime()) / MILLISECONDS_IN_HOUR);
  if (!hoursUntilMaintenance) return null;
  return hoursUntilMaintenance > 1 ? <FormattedMessage id="maintenance.site-maintenance.hours" values={{
    hours: hoursUntilMaintenance
  }} /> : <FormattedMessage id="maintenance.site-maintenance.less_than_one_hour" />;
};
const MaintenanceHeader = () => {
  const [open, setOpen] = useState(true);
  const {
    maintenance
  } = useSiteConfig();
  if (!open) return null;
  if (!maintenance) return null;
  const {
    labelId,
    borkedFunction,
    startDateTime,
    endDateTime,
    showWarningBannerDateTime,
    mode
  } = maintenance;
  const closedKey = localStorageKey(labelId, borkedFunction);
  const closed = Number(storage.getItem(closedKey));
  if (startDateTime.valueOf() === closed) return null;
  const now = new Date();
  const startCheckDate = showWarningBannerDateTime || startDateTime;
  const shouldBeVisible = startCheckDate.getTime() < now.getTime() && (!endDateTime || endDateTime.getTime() > now.getTime());
  if (!shouldBeVisible) return null;
  const hoursMessage = getHoursMessage(showWarningBannerDateTime, startDateTime);
  return <StyledStickyBanner data-sentry-element="StyledStickyBanner" data-sentry-component="MaintenanceHeader" data-sentry-source-file="index.tsx">
      {mode === 'siteMaintenance' && <>
          <FormattedMessage id="maintenance.site-maintenance.title" values={{
        startTime: startDateTime.toLocaleString(),
        hoursUntilMaintenance: hoursMessage,
        endTime: endDateTime?.toLocaleString(),
        b: text => <b>{text}</b>
      }} />
          {endDateTime && <FormattedMessage id="maintenance.site-maintenance.until" values={{
        endTime: endDateTime.toLocaleString(),
        b: text => <b>{text}</b>
      }} />}
        </>}
      {labelId && <FormattedMessage id={labelId} values={borkedFunction ? {
      borkedFunction: borkedFunction,
      b: text => <b>{text}</b>
    } : undefined} />}
      <Button onClick={() => {
      storage.setItem(closedKey, String(startDateTime.valueOf()));
      setOpen(false);
    }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <FormattedMessage id="close" data-sentry-element="FormattedMessage" data-sentry-source-file="index.tsx" />
      </Button>
    </StyledStickyBanner>;
};
export default MaintenanceHeader;