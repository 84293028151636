import(/* webpackMode: "eager", webpackExports: ["AlertComponent"] */ "/vercel/path0/apps/magicschool/src/app/(public)/auth/AlertComponentClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationTag"] */ "/vercel/path0/apps/magicschool/src/app/(public)/auth/LocalizationTagClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/magicschool/src/components/ui-component/LogoClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/magicschool/src/layout/MainLayout/Header/LocalizationSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/magicschool/src/layout/MaintenanceHeader/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Box/index.js");
